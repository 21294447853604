<template>
    <v-field-wrapper
        :id="props.id"
        :hint="hint"
        :label="label"
        :hidden-label="hiddenLabel"
        :error="error"
        :disabled="isDisabledState"
    >
        <template #default="{ bind }">
            <div class="relative ml-1">
                <div class="flex flex-col">
                    <div class="flex flex-row">
                        <select
                            v-if="searchable === false"
                            :multiple="multiple ?? false"
                            v-bind="bind"
                            v-model="dynamicValue"
                            :id="props.id"
                            :disabled="isDisabledState"
                            :name="props.id"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                            <option :value="null" :disabled="!allowNullOption">
                                Choose an option
                            </option>
                            <option
                                v-for="option in dynamicOptions"
                                :value="option.value"
                                :disabled="option.disabled ?? false"
                            >
                                {{ option.label }}
                            </option>
                        </select>
                        <vue-select
                            v-else
                            :multiple="multiple ?? false"
                            v-bind="bind"
                            v-model="dynamicValue"
                            :reduce="
                                (option) =>
                                    option.hasOwnProperty('value') ? option.value : option
                            "
                            @search="recordSearchQuery"
                            label="label"
                            :taggable="allowNewOption"
                            :id="props.id"
                            :disabled="isDisabledState"
                            :name="props.id"
                            class="bg-gray-50 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            :options="dynamicOptions"
                            :filterable="filterable"
                        >
                        </vue-select>
                        <slot name="append"></slot>
                    </div>
                    <div v-if="suggestions.length > 0">
                        <div class="flex flex-row flex-wrap">
                            <v-button
                                class="text-sm mr-1 mt-1"
                                @click="dynamicValue = suggestion.value"
                                v-for="suggestion in suggestions"
                                :key="suggestion.value"
                            >{{ suggestion.label }}</v-button
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="absolute z-90 top-0 start-0 w-full h-full bg-white/[.5] rounded-lg dark:bg-gray-800/[.4]"
                    v-if="props.loading"
                ></div>

                <div
                    class="absolute z-100 top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    v-if="props.loading"
                >
                    <div
                        class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                        role="status"
                        aria-label="loading"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </template>
    </v-field-wrapper>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import VFieldWrapper from '../FieldWrapper/VFieldWrapper.vue';
import VueSelect from 'vue-select';
import {SelectOption} from '../../Types/forms';
import {debounce} from 'lodash';

const props = withDefaults(
    defineProps<{
    options: SelectOption[];
    multiple?: boolean;
    id: string;
    label: string;
    hiddenLabel?: boolean;
    hint?: string | null;
    error?: string | null;
    modelValue?: string | number | null | (string | number)[];
    searchable?: boolean;
    disabled?: boolean;
    suggestions?: SelectOption[];
    loading?: boolean;
    allowNewOption?: boolean;
    allowNullOption?: boolean;
    filterable?: boolean;
    searchFunction?: (query: string|null) => Promise<SelectOption[]>;
  }>(),
    {
        hiddenLabel: false,
        multiple: false,
        searchable: false,
        modelValue: null,
        hint: null,
        disabled: false,
        suggestions: [],
        loading: false,
        allowNewOption: false,
        allowNullOption: false,
        searchFunction: null,
        filterable: true,
    }
);

const isDisabledState = computed<boolean>(
    () => props.disabled || props.loading
);

const overriddenOptions = ref<SelectOption[]>([]);

const dynamicOptions = computed(() => {
    if(overriddenOptions.value.length > 0) {
        return overriddenOptions.value;
    }
    return props.options;
});

const dynamicValue = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit('update:modelValue', val);
    },
});

const emit = defineEmits<{
  (
    event: 'update:modelValue',
    modelValue: string | number | string[] | number[],
  ): void;
    (event: 'update:search', query: string|null): void;
}>();

const recordSearchQuery = debounce((query: string|null, loading: (isLoading: boolean) => void) => {
    loading(true);
    emit('update:search', query);

    if(props.searchFunction !== null) {
        props.searchFunction(query)
            .then((options) => {
                overriddenOptions.value = options;
            })
            .finally(() => {
                loading(false);
            });
    } else {
        loading(false);
    }
}, 500);

</script>

<style scoped></style>
