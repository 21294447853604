<template>

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-auto">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th v-if="props.selectable">
                        <div class="ml-2">
                            <v-checkbox :disabled="selectableCount === 0" ref="selectAllCheckbox" id="selected" :model-value="modelValue.length === data.length" @update:modelValue="selectAllRows"></v-checkbox>
                        </div>
                    </th>
                    <th scope="col" :class="{[column.class]: column.class, 'px-6 py-3': !compact, 'px-1 py-1': compact}" v-for="column in columns">
                        <span class="flex flex-row">
                            <span class="flex items-center">{{ column.label }}</span>
                            <v-tooltip position="right"
                                       :text="(props.sortBy === column.key ? (props.sortByAsc ? 'Sort descending' : 'Sort ascending') : 'Sort by column')">
                                <template #activator>
                                    <button type="button" class="p-1" @click="setSort(column.key)"
                                            v-if="column.sortable ?? false">
                                        <i class="w-3 h-3 ml-1.5 fa"
                                           :class="{
                                               'fa-sort': props.sortBy !== column.key,
                                               'fa-sort-up': props.sortBy === column.key && props.sortByAsc,
                                               'fa-sort-down': props.sortBy === column.key && !props.sortByAsc
                                           }"></i>
                                    </button>
                                </template>
                            </v-tooltip>

                            <v-tooltip position="right"
                                       :text="(props.groupBy === column.key ? 'Ungroup column' : 'Group column')">
                                <template #activator>
                                    <button type="button" class="p-1 ml-1.5"
                                            :class="{
                                                'border-0': props.groupBy !== column.key,
                                                'border border-1 border-gray-900 rounded-md': props.groupBy === column.key,
                                            }"
                                            @click="setGroup(column.key)" v-if="column.groupable ?? false">
                                        <i class="w-3 h-3 fa fa-layer-group"></i>
                                    </button>
                                </template>
                            </v-tooltip>


                            <div class="normal-case">
                                <v-modal :header="'Filter by ' + column.label"
                                         v-if="column.filterable">
                                    <template #activator="{show}">
                                        <v-tooltip position="right" text="Filter by column value">
                                            <template #activator>
                                                <button type="button" class="p-1 ml-1.5"
                                                        :class="{
                                                            'border-0': props.groupBy !== column.key,
                                                            'border border-1 border-gray-900 rounded-md': props.groupBy === column.key,
                                                        }"
                                                        @click="show">
                                                    <i class="w-3 h-3 fa fa-filter"></i>
                                                </button>
                                            </template>
                                        </v-tooltip>
                                    </template>
                                    <div
                                        :class="{'px-4 py-2': !compact, 'px-2 py-1': compact}">
                                        <slot :name="'filter(' + column.key + ')'"></slot>
                                    </div>
                                    <template #footer>
                                        <slot :name="'filterSearch(' + column.key + ')'">
                                            <v-button @click="$emit('startFilter')">Search</v-button>
                                        </slot>
                                    </template>
                                </v-modal>
                            </div>

                            <v-tooltip position="right" text="Clear all sorting">
                                <template #activator>
                                    <button type="button" class="p-1" @click="clearColumn(column.key)"
                                            v-if="(column.sortable && props.sortBy === column.key) || (column.groupable && props.groupBy === column.key) || column.currentlyFiltering">
                                        <i class="w-3 h-3 ml-1.5 fa fa-circle-xmark"></i>
                                    </button>
                                </template>
                            </v-tooltip>
                        </span>
                    </th>
                    <th scope="col" :class="{'px-6 py-3': !compact, 'px-2 py-1': compact}" v-if="props.actions">
                        <span class="sr-only">Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-b dark:border-gray-700"
                    :class="shouldBeColoured ? (datum[colourKey] ?? 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600') : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600'"
                    v-for="(datum, dataIndex) in data">

                    <td v-if="props.selectable" :scope="'row'">
                        <div class="ml-2">
                            <v-checkbox
                                :disabled="isSelectable(datum) === false"
                                :id="'selected-' + datum[props.selectableKey]" :model-value="modelValue.filter(d => d[props.selectableKey] === datum[props.selectableKey]).length > 0" @update:modelValue="selectRow(datum, $event)"></v-checkbox>
                        </div>
                    </td>
                    <td v-for="(column, index) in columns" :scope="index === 0 ? 'row' : undefined"
                        :class="{'px-6 py-4': !compact, 'px-2 py-1': compact, 'font-medium text-gray-900 dark:text-white': (column.highlight ?? false)}">
                        <slot :name="'cell(' + column.key + ')'" v-bind:datum="datum" v-bind:index="dataIndex">
                            <v-table-cell
                                :data="datum"
                                :column-key="column.dataKey ?? column.key"
                                :type="column.type">
                            </v-table-cell>
                        </slot>
                    </td>
                    <td
                        :class="{'px-6 py-4': !compact, 'px-2 py-1': compact}"
                        class="text-right" v-if="props.actions">
                        <slot name="actions" v-bind:datum="datum"></slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">

import {Column} from '../../types/Table';
import VTableCell from './VTableCell.vue';
import {computed} from 'vue';
import {cloneDeep} from 'lodash';
import {setting} from '../../Tools/settings/settings';

const props = withDefaults(defineProps<{
    columns: Column[],
    data: {[key: string|number]: any}[],
    actions?: boolean,
    sortBy?: string | null,
    groupBy?: string | null,
    sortByAsc?: boolean,
    selectable?: boolean|(() => boolean),
    modelValue?: {[key: string|number]: any}[],
    selectableKey?: string,
    compact?: boolean,
    colourKey?: null | string
}>(), {
    columns: [],
    data: [],
    actions: false,
    sortBy: null,
    groupBy: null,
    sortByAsc: true,
    selectable: false,
    selectableKey: 'id',
    compact: false,
    colourKey: null
});

const setSort = (sortBy: string | null) => {
    if (props.sortBy === sortBy && sortBy !== null) {
        emit('update:sortByAsc', !props.sortByAsc);
    } else {
        emit('update:sortBy', sortBy);
    }
};

const isSelectable = (datum: {[key: string]: any}) => {
    if(typeof props.selectable === 'function') {
        return props.selectable(datum);
    }
    return props.selectable ?? false;
};

const setGroup = (groupBy: string | null) => {
    if (groupBy === props.groupBy) {
        groupBy = null;
    }
    emit('update:groupBy', groupBy);
};

const clearColumn = (key: string) => {
    setSort(null);
    setGroup(null);
    emit('clearFilter', key);
};

const emit = defineEmits<{
    (e: 'show', data: object): void
    (e: 'edit', data: object): void
    (e: 'delete', data: object): void
    (e: 'update:sortBy', sortBy: string | null): void
    (e: 'update:sortByAsc', sortByAsc: boolean): void
    (e: 'update:groupBy', groupBy: string | null): void
    (e: 'startFilter'): void
    (e: 'clearFilter', filterKey: string): void,
    (e: 'update:modelValue', modelValue: { [key: string]: any }[]): void
}>();



// Selecting
const selectableCount = computed<number>(() => {
    return props.data.filter(d => isSelectable(d)).length;
});

const selectAllRows = (select: boolean) => {
    if(select){
        emit('update:modelValue', props.data.filter(d => isSelectable(d)));
    } else {
        emit('update:modelValue', []);
    }
};

const selectRow = (data: {[key: string]: any}, selected: boolean) => {
    let value = cloneDeep(props.modelValue);
    if(selected && value.filter(d => d[props.selectableKey] === data[props.selectableKey]).length === 0) {
        value.push(data);
    } else {
        value = value.filter((d: {[key: string]: any}) => d[props.selectableKey] !== data[props.selectableKey]);
    }
    emit('update:modelValue', value);
};

const shouldBeColoured = computed<boolean>(() => {
    return setting.value['accessibility_coloured_sidebar'] ?? false;
});

</script>

<style scoped>

</style>
