import { App } from 'vue';
import {upperFirst, camelCase} from 'lodash';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

interface TailwindComponentOptions {}

export default {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    install: (app: App, options: TailwindComponentOptions) => {
        const components = import.meta.glob('./Ui/**/*.vue', {eager: true});

        Object.entries(components).forEach(([path, m]) => {
            const componentName = upperFirst(
                camelCase(path.split('/').pop().replace(/\.\w+$/, ''))
            );

            app.component(
                `${componentName}`, m.default
            );
        });

        app.component('vue-date-picker', VueDatePicker);

    }
};
