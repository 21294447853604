<template>
    <div class="flex flex-row w-full">
        <div class="flex flex-col w-full">
            <v-select :searchable="true"
                      v-model="dynamicSku"
                      :options="skuOptions"
                      :label="props.label"
                      :search-function="loadSkus"
                      :hint="props.hint"
                      :error="props.error"
                      :multiple="props.multiple"
                      :id="props.id">

            </v-select>
        </div>

        <!--        <v-modal header="Kit Builder" ref="modal">-->
        <!--            <template #activator="{show}">-->
        <!--                <v-tooltip text="Open Kit Builder" position="left">-->
        <!--                    <template #activator>-->
        <!--                        <v-icon-button @click="show" class="mt-4">-->
        <!--                            <i class="fa fa-hammer px-2"></i>-->
        <!--                        </v-icon-button>-->
        <!--                    </template>-->
        <!--                </v-tooltip>-->
        <!--            </template>-->
        <!--            <div>-->
        <!--            </div>-->
        <!--        </v-modal>-->

        <div class="ml-4 mt-4 h-full flex justify-center items-center" v-if="props.showCost">
            <div class="pb-2 font-bold">${{ skuCost ?? '?' }}</div>
        </div>
    </div>

</template>

<script lang="ts" setup>

import {computed, ref, watch, watchEffect} from 'vue';
import VSelect from '../Select/VSelect.vue';
import {SelectOption} from '../../Types/forms';
import {debounce} from 'lodash';
import axios from 'axios';
import { route } from 'ziggy-js';

interface SkuOption {
    sku: string,
    cost_value_usd: number,
}

const props = withDefaults(
    defineProps<{
        onlyDispatchableSkus: boolean,
        label: string,
        id: string,
        modelValue: string|null|string[],
        showCost: boolean,
        costMultiplier: number,
        hint?: string,
        error?: string,
        multiple?: boolean
  }>(), {
        onlyDispatchableSkus: false,
        modelValue: null,
        showCost: false,
        costMultiplier: 1,
        hint: null,
        error: null,
        multiple: false
    }
);

const emit = defineEmits<{
    (event: 'update:modelValue', modelValue: string|null): void
}>();

const skus = ref<SkuOption[]>([]);

const skuOptions = computed<SelectOption[]>(() => {
    return skus.value.map(sku => {
        return {
            label: sku.sku,
            value: sku.sku
        };
    });
});

const dynamicSku = computed<string|null>({
    get() {
        return props.modelValue ?? null;
    },
    set(value: string|null) {
        emit('update:modelValue', value);
    }
});

const searchQuery = ref<string|null>(null);

watch(searchQuery, debounce(() => {
    if(searchQuery.value !== null) {
        loadSkus(searchQuery.value);
    }
}, 500));

const loadingSkus = ref<boolean>(false);

const loadSkus = (query: string | null , loadAll: boolean = false): Promise<SelectOption[]> => {
    if(query === null) {
        return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
        axios.get(route('api.tools.skus.skus.index', {
            query: query,
            // Load all SKUs if loadAll is true, otherwise default to the toggle
            onlyDispatchable: loadAll ? false : props.onlyDispatchableSkus
        }))
            .then(response => {
                skus.value = response.data.skus;
                resolve(response.data.skus.map((sku: SkuOption) => {
                    return {
                        label: sku.sku,
                        value: sku.sku
                    };
                }));
            })
            .catch(error => {
                reject(error);
            })
            .finally(() => {
                loadingSkus.value = false;
            });
    });
};

const skuCost = computed<number|null>(() => {
    if(props.modelValue === null) {
        return null;
    }
    let cost = skus.value.find(s => s.sku === props.modelValue)?.cost_value_usd ?? null;
    if(cost !== null) {
        return cost * props.costMultiplier;
    }
    return null;
});

watchEffect(() => {
    if(props.modelValue !== null) {
        if(Array.isArray(props.modelValue)) {
            loadSkus(props.modelValue.join(','), true);
        } else {
            loadSkus(props.modelValue, true);
        }
    }
});

</script>

<style scoped>

</style>
